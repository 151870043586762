import { createStore } from 'zustand-x';
import { feathers } from '@webfx/core-web';

export const DownloadsStore = createStore('downloads')({
  items: new Map(),
}).extendActions((set, get) => ({
  /**
   * Adds a new download job to the store
   * @param {object} payload - The download job configuration
   * @param {object} payload.query - Query parameters to pass to the export services create call
   * @param {string} payload.exportService - The export service to use to create the polling job
   * @param {boolean} payload.skipCreate - If true, uses jobData directly instead of doing a create call to the export service
   * @param {string} payload.jobId - The jobId of the from the created queue job. Required when skipCreate is true.
   * @param {string} payload.queue - The queue name used by the created job. Required when skipCreate is true.
   * @param {string} payload.filename - The name of the file to be downloaded. Required when skipCreate is true.
   * @param {string} payload.extension - The file extension of the file to be downloaded. Required when skipCreate is true.
   * @returns {Promise<void>}
   */
  add: async (payload) => {
    const { query, exportService = 'mcfx/export', skipCreate = false, ...jobData } = payload;
    const { queue, filename, extension, ...data } = skipCreate
      ? jobData
      : await feathers().service(exportService).create({}, { query });

    if (!queue || !filename || !data.jobId) {
      throw new Error('Missing required job data');
    }

    const jobId = `${queue}/${data.jobId}`;
    const items = get.items();

    items.set(jobId, {
      id: jobId,
      filename,
      queue,
      extension,
      exportService,
    });

    // zustand doesn't deeply compare maps, so we need to create a new map
    // to trigger a re-render
    set.items(new Map(items));
  },

  /**
   * Removes a specific download job from the store
   * @param {string} id - The job ID to remove in format 'queue/jobId'
   */
  delete: (id) => {
    const items = get.items();
    items.delete(id);

    set.items(new Map(items));
  },

  /**
   * Clears all download jobs from the store
   */
  clear: () => {
    set.items(new Map());
  },
}));
