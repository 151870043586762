import { actions, useTrackedStore } from '@webfx/web-state';

export function useDownloads() {
  const store = useTrackedStore();
  return {
    items: Array.from(store.downloads.items().values()),
    add: actions.downloads.add,
    delete: actions.downloads.delete,
    clear: actions.downloads.clear,
    download,
  };
}

/**
 * A generic download utility to force a file download from a url using datablob and a hidden anchor
 * @param {string} url - The URL of the file to download
 * @param {string} defaultFilename - The default filename to use if none is provided in Content-Disposition
 * @returns {Promise<void>} A promise that resolves when the download is complete
 * @throws {Error} If the HTTP request fails or download cannot be completed
 */
async function download(url, defaultFilename) {
  console.log('download', url, defaultFilename);

  try {
    const response = await fetch(url, {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    let filename = defaultFilename;
    const disposition = response.headers.get('Content-Disposition');

    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);

      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }

    const blob = await response.blob();
    const URL = window.URL || window.webkitURL;
    const downloadUrl = URL.createObjectURL(blob);

    if (filename) {
      const a = document.createElement('a');
      if (typeof a.download === 'undefined') {
        window.location = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } else {
      window.location = downloadUrl;
    }

    setTimeout(() => URL.revokeObjectURL(downloadUrl), 100);
  } catch (error) {
    console.error('Download failed:', error);
    throw error;
  }
}
