import React, { useState } from 'react';
import numbro from 'numbro';
import classnames from 'classnames';
import { api, IconLabel, Button, Typography } from '@webfx/core-web';
import { useDownloads } from './useDownloads';

import DownloadItem from './DownloadItem/DownloadItem';
import './Downloads.style.css';

export function Downloads() {
  const [isMinimized, setIsMinimized] = useState(false);
  const { items, clear } = useDownloads();
  const downloads = api.useQueries(
    items.map((item) => ({
      queryKey: [`queues/${item.id}`, { queue: item.queue }],
      refetchInterval: (data) => (data?.progress !== 100 ? 2000 : false),
      refetchIntervalInBackground: true,
    }))
  );

  const progress = downloads.length
    ? numbro(
        downloads.reduce((accum, download) => accum + (download.data?.progress ?? 0), 0) /
          downloads.length
      ).format({
        trimMantissa: true,
        mantissa: 1,
      })
    : 0;

  return downloads.length ? (
    <div
      className={classnames({
        'downloads-wrapper': true,
        minimized: isMinimized,
      })}
      data-fx-name="downloadWindow"
    >
      <div className="downloads-header">
        <IconLabel
          labelClassName="bold"
          iconClassName="download-icon"
          icon="system_update_alt"
          label={`Downloads (${downloads.length})`}
          className="flex-grow-1"
        />
        <Typography className="text-secondary font-12 mb-0 mr-2">{progress}%</Typography>
        {progress != 100 ? (
          <Button
            icon="stop"
            variant="light"
            onClick={() => {
              clear();
            }}
            className="downloads-clearall mr-2 bg-white"
            iconClassName="downloads-clearall-icon"
            data-fx-name="stopDownloadButton"
          />
        ) : null}
        {progress == 100 ? (
          <Button
            text="Clear"
            variant="light"
            onClick={() => {
              clear();
            }}
            className="mr-2 bg-white border-0"
          />
        ) : null}
        <Button
          icon={isMinimized ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          variant="light"
          className="border-0 p-0 text-dark bg-white"
          onClick={() => {
            setIsMinimized(!isMinimized);
          }}
        />
      </div>
      <div className="download-items" data-fx-name="exportForm">
        {downloads.map((download, index) => (
          <DownloadItem
            key={items[index].filename}
            download={items[index]}
            progress={download.data?.progress ?? 0}
          />
        ))}
      </div>
    </div>
  ) : null;
}
