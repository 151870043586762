import React, { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import deepmerge from 'deepmerge';
import qs from 'qs';
import isPlainObject from 'lodash/isPlainObject';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import set from 'lodash/set';
import get from 'lodash/get';
import castArray from 'lodash/castArray';
import { DateTime } from 'luxon';

/**
 * A React hook that manages URL query parameters as filters, with support for Feathers-compatible queries.
 * @param {object} options - Configuration options for the hook
 * @param {object} [options.defaults={}] - Default filter values. this should be a memoized object
 * @param {string[]} [options.hiddenKeys] - Array of keys to exclude from the URL
 * @param {string[]} [options.replaceKeys] - Keys that should replace their existing values instead of merging
 * @param {boolean} [options.ignoreKeys] - Keys to not include in returned "filters" object
 * @param {object} [options.transformKeys={}] - Map of keys to transform in the resulting query
 * @returns {object} An object containing:
 *   - filters {Object} Current filter values including defaults
 *   - query {Object} Transformed query compatible with Feathers
 *   - setFiltersTo {Function} Set all filters at once
 *   - resetFilters {Function} Reset filters to defaults
 *   - setFilter {Function} Set individual filter value
 *   - setFilterOnChange {Function} Returns an onChange handler for a specific field
 * @example
 * // Basic usage
 * const { filters, setFilter } = useQueryParamFilters({
 *   defaults: { status: 'active' }
 * });
 *
 * // Set a single filter
 * setFilter('status', 'pending');
 *
 * // Set multiple filters
 * setFilter({ status: 'pending', type: 'user' });
 *
 * @example
 * // Using with a select component
 * const { setFilterOnChange } = useQueryParamFilters();
 *
 * return (
 *   <Select
 *     onChange={setFilterOnChange('status')}
 *     options={['active', 'pending']}
 *   />
 * );
 *
 * @example
 * // Transform keys for backend compatibility
 * const { query } = useQueryParamFilters({
 *   transformKeys: {
 *     userId: 'user._id'  // transforms userId filter to user._id in query
 *   }
 * });
 */
export function useQueryParamFilters({
  defaults = {},
  hiddenKeys,
  replaceKeys,
  ignoreKeys,
  transformKeys = {},
} = {}) {
  const location = useLocation();
  const history = useHistory();

  const searchParamTransformKeys = Object.entries(transformKeys).reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
  }, {});

  const searchParams = Object.entries(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
      arrayLimit: 1000,
    })
  ).reduce((acc, [key, value]) => {
    // Check if key exists in searchParamTransformKeys
    if (key in searchParamTransformKeys) {
      set(acc, searchParamTransformKeys[key], value);
      return acc;
    }

    // Check if any transformed keys exist in nested value object
    if (isPlainObject(value)) {
      Object.entries(searchParamTransformKeys).forEach(([transformedKey, originalKey]) => {
        const nestedValue = get(value, transformedKey);
        if (nestedValue !== undefined) {
          set(acc, originalKey, nestedValue);
          return;
        }
      });
    }

    acc[key] = value;
    return acc;
  }, {});

  const filters = deepmerge(
    defaults,
    ignoreKeys
      ? Object.entries(searchParams).reduce((acc, [key, value]) => {
          if (!ignoreKeys.includes(key)) {
            acc[key] = value;
          }
          return acc;
        }, {})
      : searchParams,
    { arrayMerge: overwriteMerge }
  );
  const query = transformToFeathersQuery(filters, transformKeys);

  if (query.$sort) {
    Object.keys(query.$sort).forEach((key) => {
      query.$sort[key] = parseInt(query.$sort[key], 10);
    });
  }

  if (query?.date) {
    if (isPlainObject(query.date)) {
      Object.keys(query.date).forEach((key) => {
        query.date[key] =
          key === '$lte'
            ? DateTime.fromISO(query.date[key]).endOf('day').toUTC().toISO()
            : DateTime.fromISO(query.date[key]).toUTC().toISO();
      });
    } else {
      query.date = DateTime.fromISO(query.date).toUTC().toISO();
    }
  }

  const filterKeys = (key, value) => {
    if (
      (Array.isArray(hiddenKeys) && hiddenKeys.includes(key)) ||
      (Array.isArray(ignoreKeys) && ignoreKeys.includes(key))
    ) {
      return;
    }

    // eslint-disable-next-line eqeqeq -- dont want to be strict
    if (key === '$skip' && value == 0) {
      return;
    }

    if (value === '') {
      return;
    }

    // eslint-disable-next-line eqeqeq -- dont want to be strict
    if (defaults[key] == value) {
      return;
    }

    if (isPlainObject(value)) {
      if (JSON.stringify(value) === JSON.stringify(defaults[key] || {})) {
        return;
      }
    }

    if (!isEmpty(replaceKeys) && checkKeysExist(value, replaceKeys)) {
      let replacedValue = '';
      replaceKeys.forEach((key) => {
        replacedValue = replaceLastKey(value, key);
      });
      return replacedValue;
    }

    return value;
  };

  /**
   * Sets the filters to the given object and updates the URL search query accordingly.
   * @param {Object} filters - The filters to set.
   * @returns {void}
   */
  const setFiltersTo = (filters) => {
    if (JSON.stringify(filters) === JSON.stringify(defaults)) {
      history.push({ search: '' });
      return;
    }

    history.push({
      search: qs.stringify(filters, {
        filter: filterKeys,
        encodeValuesOnly: true,
      }),
    });
  };

  /**
   * Resets the filters by updating the URL search query to an empty string.
   * @returns {void}
   */
  const resetFilters = () => {
    return history.push({ search: '' });
  };

  /**
   * Sets a single filter value or multiple filter values at once.
   * @param {(string|object)} keyOrValues - Either a string key for a single filter, or an object containing multiple key-value pairs
   * @param {*} [value] - The value to set when using a single key. Ignored when keyOrValues is an object
   * @returns {void}
   * @example
   * // Set a single filter
   * setFilter('status', 'active');
   *
   * // Set multiple filters at once
   * setFilter({ status: 'active', type: 'user' });
   *
   * // Remove a filter by setting to undefined/null
   * setFilter('status', undefined);
   */
  const setFilter = (keyOrValues, value) => {
    const setting =
      typeof keyOrValues === 'string'
        ? { [keyOrValues]: value, $skip: 0 }
        : { $skip: 0, ...keyOrValues };
    const toSet = deepmerge(searchParams, setting, { arrayMerge: overwriteMerge });

    // force deletes
    Object.keys(toSet).forEach((key) => {
      if (toSet[key] === undefined || toSet[key] === null) {
        delete toSet[key];
      }
    });

    history.push({
      search: qs.stringify(toSet, {
        filter: filterKeys,
        encodeValuesOnly: true,
        arrayFormat: 'repeat',
      }),
    });
  };

  /**
   * onChange handle to set filter in url
   * uses currying to set a specific field by name
   * @param {string} field
   * @returns
   */
  const setFilterOnChange = (field) => (value) => {
    if (!value) {
      setFilter(field, undefined);
      return;
    }

    setFilter(field, value);
  };

  /**
   * Toggles a value in a field
   * @param {string} field
   * @param {*} value
   */
  const toggleValueInKey = (field, value) => {
    const currentValue = castArray(filters[field] ?? []);

    const newValue = currentValue.includes(value)
      ? currentValue.filter((v) => v !== value)
      : [...currentValue, value];
    setFilter(field, newValue.length === 1 ? newValue[0] : newValue);
  };

  return useMemo(
    () => ({
      filters,
      query,
      setFiltersTo,
      resetFilters,
      setFilter,
      setFilterOnChange,
      toggleValueInKey,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only need to update the object based onfilters and query changing
    [JSON.stringify(filters), JSON.stringify(query)]
  );
}

/**
 * Transforms a plain JavaScript object to a Feathers query object.
 * @param {object} obj - The object to transform.
 * @param {object} [transformKeys={}] - An object containing key-value pairs to transform keys in the object.
 * @returns {object} The transformed Feathers query object.
 */
function transformToFeathersQuery(obj, transformKeys = {}) {
  if (!isPlainObject(obj)) {
    return obj;
  }

  const clone = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value) && key !== '$in') {
      if (value.length === 1) {
        clone[transformKeys[key] ?? key] = value[0];
        return;
      }
      clone[transformKeys[key] ?? key] = { $in: value };
      return;
    }
    if (isPlainObject(value)) {
      clone[transformKeys[key] ?? key] = transformToFeathersQuery(value, transformKeys);
    }

    clone[transformKeys[key] ?? key] = value;
  });

  return clone;
}

// eslint-disable-next-line no-unused-vars -- exclude next line (options)
function overwriteMerge(destinationArray, sourceArray, options) {
  return sourceArray;
}

// replaces multiple keys with the last key
export function replaceLastKey(value, key) {
  if (value && typeof value === 'object' && key in value && typeof value[key] === 'object') {
    const sortKeys = Object.keys(value[key]);
    if (sortKeys.length > 1) {
      const lastSortKey = sortKeys.pop();
      value[key] = { [lastSortKey]: value[key][lastSortKey] };
    }
  }
  return value;
}

function checkKeysExist(obj, keysToCheck) {
  const results = {};
  keysToCheck.forEach((key) => {
    results[key] = {
      hasOwnProperty: has(obj, key),
    };
  });
  return results;
}
