import { castArray, isEmpty } from 'lodash';
import { api } from '@webfx/core-web';
import { educationLevels } from '../../marketingcloud-web/src/constants/pdlConstants';
import useActiveSite from './useActiveSite';
import useParamFilters from './useParamFilters';
import useFlags from './useFlags';
const { extractCountryRegion, getTagValues } = require('@webfx/formatting');
/**
 * Queries the idp/person endpoint with the current siteId
 * and query params based on the current route
 * @param {object} params
 * @param {object} params.passThroughFilters additional filters to apply to the query
 * @param {object} options additional options to pass to the useQuery hook
 * @param {?string} calledBy the product invoking the hook. Used for logging purposes
 * @returns {object} the query object result
 */
export default function usePersonSearch({ passThroughFilters = {} } = {}, options = {}, calledBy) {
  const { siteId } = useActiveSite();
  const flags = useFlags();
  const {
    query: { pagination, useIntentData },
  } = useParamFilters();
  let filters = useParamFilters().query.filters;

  if (useIntentData && flags.prospectorIntentData) {
    filters = {
      ...filters,
      $useIntentData: true,
    };
  }

  const queryKey = [
    'idp/person',
    {
      query: {
        siteId,
        ...normalizeFilters(filters),
        ...normalizePagination(pagination),
        $join: ['fxContact'],
        ...passThroughFilters,
      },
      ...(calledBy ? { headers: { 'x-fx-called-by': calledBy } } : {}),
    },
  ];

  const person = api.useQuery(queryKey, {
    ...options,
    staleTime: Infinity, // disable refetching and only query when the query key changes
    enabled: !isEmpty({ ...filters } || {}) || options.enabled === true,
  });
  return { ...person, queryKey };
}

function normalizePagination(pagination = {}) {
  const $page = pagination?.page ?? 1;
  const $limit = pagination?.limit ?? 10;
  let $sort = pagination?.sortBy || '_score_desc';
  if ($sort) {
    const sortKey = $sort.split('_desc')[0];
    $sort = {
      [sortKey]: $sort.endsWith('_desc') ? -1 : 1,
      ...(sortKey === '_score' ? { contactInfoCount: -1 } : {}),
    };
  }
  return {
    ...($sort ? { $sort } : {}),
    ...($page ? { $skip: ($page - 1) * $limit } : {}),
    ...($limit ? { $limit } : {}),
  };
}

/**
 *  Normalizes the filters object to be used in the api query
 * @param {object} filters the filters object
 * @returns {object} the normalized filters object
 */
export function normalizeFilters(filters = {}) {
  const parsedFilters = getTagValues(filters);
  const normalizedFilters = {
    $and: [],
    $or: [],
    ...(filters.$useIntentData ? { $useIntentData: filters.$useIntentData } : {}),
    ...(parsedFilters.$exists ? { $exists: parsedFilters.$exists } : {}),
  };
  const omit = ['excludes', '$exists', '$useIntentData'];
  const keymaps = {
    jobTitle: 'jobTitle.text',
  };
  Object.entries(parsedFilters).forEach(([key, value]) => {
    if (omit.includes(key)) {
      return;
    }
    if (parsers[key]) {
      let parsedValue = parsers[key](value, filters);

      // Education level requires nesting $in and $nin inside of $or. Nesting that inside of $and would throw a "must not have additional properties" error.
      if (key === 'educationLevel') {
        normalizedFilters['$or'] = parsedValue;
        return;
      }

      if (Array.isArray(parsedValue)) {
        const additionalFilters = parsedValue[1];
        parsedValue = parsedValue[0];
        if (additionalFilters) {
          Object.entries(additionalFilters).forEach(([key, value]) => {
            normalizedFilters[key] = value;
          });
        }
      }

      normalizedFilters.$and.push(parsedValue);
      return;
    }
    if (value.$in && key.split('.').pop() !== 'exact') {
      // if the key ends with exact, we want to perform a term search
      const orFilters = value.$in.map((val) => ({ [keymaps[key] || key]: val }));
      normalizedFilters.$and.push({ $or: orFilters });
      return;
    }
    normalizedFilters.$and.push({ [key.replace(/\.exact$/, '')]: value });
  });
  return normalizedFilters;
}

const parsers = {
  location: (value, filters) => {
    const location = value;
    return extractCountryRegion(
      location,
      filters?.excludes?.$in.includes('location') ? '$nin' : '$in'
    );
  },
  jobCompanyLocation: (value) => {
    const location = value;
    return extractCountryRegion(location, '$in', 'jobCompany');
  },
  email: (value) => {
    const emails = castArray(value?.$in || value);
    return {
      $or: emails
        .map((email) => [
          { personalEmails: email },
          { workEmail: email },
          { recommendedPersonalEmail: email },
          { 'emails.address': email },
          { 'possibleEmails.address': email },
        ])
        .flat(),
    };
  },
  jobDepartment: (value) => {
    const jobDepartments = castArray(value?.$in || value);
    return {
      $or: jobDepartments.map((department) => ({ jobTitleRole: department })),
    };
  },
  jobCompanyName: (value) => {
    const jobCompanyNames = castArray(value?.$in || value);
    return {
      $or: jobCompanyNames
        .map((companyName) => [
          { jobCompanyName: companyName },
          { kfCompanyUniqueName: companyName },
        ])
        .flat(),
    };
  },
  kfCompanyLocation: (value, filters) => {
    const location = value;
    return extractCountryRegion(
      location,
      filters?.excludes?.$in.includes('kfCompanyLocation') ? '$nin' : '$in',
      'kfCompanyLocation'
    );
  },
  kfCompanyNAICSCode: (value, filters) => {
    const inOrNinMap = (value.$in ?? value.$nin)?.map((industry) => {
      return { kfCompanyNAICSCode: industry };
    });
    return [
      {
        $or: inOrNinMap,
      },
      {
        $excludeNAICS: filters.excludes?.$in?.includes('kfCompanyNAICSCode'),
        $prefixNAICS: true,
      },
    ];
  },
  kfCompanyEmployees: (value) => {
    const { $gte: minEmployees, $lte: maxEmployees } = value;
    return {
      $or: ['jobCompanySize', 'kfCompanyEmployees'].map((key) => ({
        [key]: {
          ...(minEmployees ? { $gte: minEmployees } : {}),
          ...(maxEmployees ? { $lte: maxEmployees } : {}),
        },
      })),
    };
  },
  age: (value) => {
    const { $gte: minAge, $lte: maxAge } = value;
    const currentYear = new Date().getFullYear();
    return {
      birthYear: {
        ...(minAge ? { $lte: currentYear - minAge } : {}), // Age greater than or equal to X means birth year is less than or equal to current year - X.
        ...(maxAge ? { $gte: currentYear - maxAge } : {}), // And the reverse here. This may include/exclude people on the edge of the range depending on their exact birth date.
      },
    };
  },
  educationLevel: (value) => {
    const desiredEducationLevels = castArray(value?.$in || value);
    const groups = groupEducationLevels(desiredEducationLevels);
    return groups.map((group) => {
      return {
        'education.degrees': {
          $in: group,
          $nin: excludedEducationLevels(group[group.length - 1]),
        },
      };
    });
  },
};

// Optimizes the education query by combining adjacent degree levels into a single $in and $nin query.
function groupEducationLevels(desiredEducationLevels) {
  const groups = [];
  let group = [];
  for (const degree of educationLevels) {
    if (desiredEducationLevels.includes(degree.value)) {
      group.push(degree.value);
    } else if (group.length > 0) {
      groups.push(group);
      group = [];
    }
  }
  if (group.length > 0) {
    groups.push(group);
  }
  return groups;
}

// If you have a PhD, you don't qualify for the bachelor's degree search.
function excludedEducationLevels(maxEducationLevel) {
  const degreeIndex = educationLevels.findIndex((degree) => degree.value === maxEducationLevel);
  return educationLevels.slice(degreeIndex + 1).map((degree) => degree.value);
}
