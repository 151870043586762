import React from 'react';
import classNames from 'classnames';
import styles from './Screenshot.module.css';

const authKey = process.env.POI_APP_THUM_IO_AUTH_KEY;

/**
 * Screenshot component to display a thumbnail image of a given URL.
 * @param {object} root0 - The props object.
 * @param {string} root0.url - The URL to generate the screenshot for.
 * @param {number} [root0.width] - The width of the screenshot.
 * @param {number} [root0.crop] - The crop size of the screenshot.
 * @param {string} [root0.className] - Additional class names for the component.
 * @param {boolean} [root0.returnNullOnInvalidUrl] - Whether to show an error message if the URL is invalid.
 * @param {object} [root0.passThroughProps] - Additional props to pass through to the component.
 * @returns {React.ReactElement} The screenshot component.
 */
export default function Screenshot({
  url,
  width = 200,
  crop = 640,
  className,
  returnNullOnInvalidUrl = false,
  ...passThroughProps
}) {
  const [isValidUrl, setIsValidUrl] = React.useState(!returnNullOnInvalidUrl);

  React.useEffect(() => {
    if (!returnNullOnInvalidUrl) {
      return;
    }
    const checkUrl = async () => {
      // This is mainly to check if the URL is valid. Any response from the URL is considered valid.
      // The main concern is to prevent screenshots from being generated for unreachable URLs.
      try {
        await fetch(url.match(/^http/) ? url : `http://${url}`, {
          method: 'HEAD',
          mode: 'no-cors',
          credentials: 'include',
          headers: {
            'User-Agent':
              'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.3',
            'Accept-Language': 'en-US,en;q=0.9',
          },
        });
        setIsValidUrl(true);
      } catch (error) {
        console.error('Error checking url', url, error);
        setIsValidUrl(false);
      }
    };

    checkUrl();
  }, [url, returnNullOnInvalidUrl]);

  if (!url || !isValidUrl) {
    return returnNullOnInvalidUrl ? null : (
      <div className={classNames(styles.error, className)} {...passThroughProps}>
        NO THUMBNAIL FOUND
      </div>
    );
  }

  const src = `//image.thum.io/get/auth/${authKey}/width/${width}/crop/${crop}/${
    url.match(/^http/) ? url : `http://${url}`
  }`;
  return <img src={src} className={className} {...passThroughProps} />;
}
