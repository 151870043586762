import React, { useState, useEffect } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { FileImg, Button } from '@webfx/core-web';
import { useDownloads } from '../useDownloads';
import './DownloadItem.style.css';

const DownloadItem = ({ download, progress = 0 }) => {
  const { delete: deleteDownload, download: downloadFile } = useDownloads();
  const [triggeredDownload, setTriggeredDownload] = useState(false);

  const fileName =
    download.filename.length >= 33
      ? `${download.filename.substring(0, 30)}...`
      : `${download.filename}.`;

  useEffect(() => {
    if (progress == 100 && !triggeredDownload) {
      setTriggeredDownload(true);

      downloadFile(
        `${process.env.POI_APP_API}/${download.exportService.replace(/(^\/?)|(\/?)$/g, '')}/${
          download.filename
        }`,
        `${download.filename}.${download.extension}`
      );
    }
  }, [progress, triggeredDownload, setTriggeredDownload, downloadFile]);

  return (
    <div className="downloaditem-wrapper" data-fx-name="downloadModal">
      <FileImg type={download.extension} className="mr-2" />
      <div className="w-100">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="mb-0 font-12" data-fx-name="downloadName">
            {fileName}
            {download.extension}
          </h3>
          <Button
            icon="clear"
            variant="light"
            className="border-0 p-0 bg-white"
            onClick={() => {
              deleteDownload(download.id);
            }}
          />
        </div>
        <ProgressBar className="downloaditem-progress-bar mt-2 mb-2" now={progress} />
        <p className="text-secondary font-12" data-fx-name="downloadProgress">
          {progress}%
        </p>
      </div>
    </div>
  );
};

export default DownloadItem;
